@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Büyük ekranlarda diagonal kesik */
.clip-diagonal-right {
  clip-path: polygon(70% 0%, 100% 0%, 100% 100%, 30% 100%);
}

/* Mobil ekranlarda daha küçük bir diagonal kesik */
.clip-diagonal-right-mobile {
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
}
/* İletişim sayfası için genel stil ayarları */
.container {
  max-width: 1200px;
  margin: auto;
}
/* Form inputları için genel stil ayarları */
input,
textarea {
  outline: none;
}

input:focus,
textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.arsiv-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  justify-content: center; /* Center cards on the page */
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-content h3 {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.card-content p {
  font-size: 1rem;
  flex-grow: 1;
  word-wrap: break-word; /* Ensures text wraps correctly */
}

.card-content small {
  margin-top: 12px;
  font-size: 0.8rem;
  color: #555;
}

/* Responsive adjustments */
@media (max-width: 768px) { /* Tablet view */
  .card {
    max-width: 100%; /* Use full width on smaller screens */
  }
}

@media (max-width: 480px) { /* Mobile view */
  .arsiv-container {
    flex-direction: column; /* Stack cards vertically */
  }

  .card {
    max-width: 100%; /* Full width for mobile */
  }
}
