/* Calendar Container */
.react-calendar {
    @apply border-none rounded-xl shadow-xl bg-white p-6;
  }
  
  /* Calendar Header */
  .react-calendar__navigation {
    @apply flex justify-between items-center text-gray-800 font-semibold text-lg;
  }
  
  .react-calendar__navigation button {
    @apply text-gray-500 hover:text-gray-700 transition ease-in-out duration-200 transform hover:scale-105;
  }
  
  /* Weekday Labels */
  .react-calendar__month-view__weekdays {
    @apply text-gray-500 uppercase font-medium text-sm pb-2;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    @apply text-center;
  }
  
  /* Day Tiles */
  .react-calendar__tile {
    @apply text-gray-900 rounded-lg p-2 transition-all duration-200 ease-in-out transform hover:scale-105;
  }
  
  .react-calendar__tile:hover,
  .react-calendar__tile:focus {
    @apply bg-gray-100 text-gray-900 shadow-md;
  }
  
  /* Selected Day */
  .react-calendar__tile--active {
    @apply bg-blue-500 text-white font-semibold shadow-md;
  }
  
  .react-calendar__tile--active:hover {
    @apply bg-blue-600;
  }
  
  /* Today’s Date */
  .react-calendar__tile--now {
    @apply bg-gray-200 font-medium;
  }
  
  .react-calendar__tile--now:hover {
    @apply bg-gray-300;
  }
  
  /* Disabled Days */
  .react-calendar__tile--disabled {
    @apply text-gray-300 cursor-not-allowed;
  }
  
  /* Weekend Days */
  .react-calendar__month-view__days__day--weekend {
    @apply text-gray-400;
  }
  
  /* Small Screen Adjustments */
  @media (max-width: 768px) {
    .react-calendar {
      @apply p-4;
    }
  
    .react-calendar__navigation button {
      @apply text-base;
    }
  
    .react-calendar__tile {
      @apply text-sm p-1;
    }
  }
  